import { gsap } from 'gsap';

const onScrollAnimation = (target) => {
  const targets = [...document.querySelectorAll(target)];

  targets.forEach((item) => {
    const offset = item.offsetHeight > 300 ? 0.1 : 0.2;
    const options = { rootMargin: '0px', threshold: offset };

    const callBack = (entries, observer) => {
      if(entries[0].isIntersecting) {
        const time = entries[0].target.offsetHeight > 300 ? 1.5 : 1;
        gsap.to(entries[0].target, { opacity: 1, y: 0, duration: time, ease: 'power2.out', });
        observer.disconnect();
      }
    };

    const observer = new IntersectionObserver(callBack, options);
    observer.observe(item);
  });

};

export default onScrollAnimation;
