import { gsap } from 'gsap';

const firstScreenAnimation = () => {
  const HEADER = document.querySelector('.header');
  const TITLE_GROUP = document.querySelectorAll('.intro__title, .intro__text p, .intro__button');
  const SOCIAL_BLOCK = document.querySelector('.intro .social');

  return new Promise((resolve) => {
    gsap.fromTo(HEADER, { y: -40 },{ opacity: 1, y: 0, duration: 1, onComplete: () => {
      gsap.fromTo(TITLE_GROUP, { y: 120 }, { opacity: 1, y: 0, duration: 0.8, stagger: 0.05, onStart: () => {
          return resolve(true);
        }});
      gsap.fromTo(SOCIAL_BLOCK, { y: -50 }, { opacity: 1, y: 0, duration: 0.5 });
    }});
  });
};


export default firstScreenAnimation;
