import 'jquery-mask-plugin';
import 'jquery-validation';

export default () => {
  $('input[type="tel"]').mask(`+380 (00) 000-00-00`);

  // eslint-disable-next-line func-names
  jQuery.validator.addMethod('phoneno', function (phoneNumber, element) {
    return this.optional(element) || phoneNumber.match(/\+[0-9]{3}\s\([0-9]{2}\)\s[0-9]{3}-[0-9]{2}-[0-9]{2}/);
  });

  // eslint-disable-next-line func-names
  $.validator.addMethod("regex",function(value, element, regexp) {
      return this.optional(element) || new RegExp(regexp).test(value);
  });

  $('.form__field-wrapper input').each((index, item) => {
    $(item).on('focus input', (event) => {
      if (event.target.value.length || event.type === 'focus') {
        $(item).addClass('active');
      }
    });

    $(item).on('focusout', (event) => {
      if(!event.target.value.length) {
        $(item).removeClass('active');
      }
    });
  });

  $('.form__field__select--wrapper').each((index, block) => {
    const button = $(block).find('.form__field__select');
    const select = $(block).find('.form__select__list');
    const listItem = $(select).find('li');
    const input = $(block).find('.form__input-select');

    $(button).click(() => {
      $(select).slideToggle(200);
      $(input).focus();
    });
    // eslint-disable-next-line func-names
    $(listItem).click(function() {
      $(input).val($(this).html());
      $(select).slideToggle(200);
      $(input).focusout();
    });
  });

  const clearFields = (form) => {
    $(form).trigger('reset');
    $('input').focusout().removeClass('active, valid');
  };

  const startSubmitLoader = () => {
    const lang = $('.form__status').attr('data-lang');
    const text = {
      en: 'sending...',
      ru: 'отправка...',
      ua: 'відправка...'
    };
    $('.form__status').html(text[lang]).fadeIn();
  };

  const successSubmit = () => {
    const lang = $('.form__status').attr('data-lang');
    const text = {
      en: 'Done, thank you for message!',
      ru: 'Отправлено, спасибо за сообщение!',
      ua: 'Відправлено, дякуємо за повідомлення!',
    };
    $('.form__status').html(text[lang]).fadeIn();
  };

  const failSubmit = () => {
    const lang = $('.form__status').attr('data-lang');
    const text = {
      en: 'error, please write us email',
      ru: 'Ошибка отправки, напишите пожалуйста нам на почту',
      ua: 'Помилка відправки, будь-ласка напішить нам на пошту'
    };
    $('.form__status').html(text[lang]).fadeIn();
  };

  const clearMSG = () => {
    $('.form__status').html('').fadeOut();
    $('.form__loader').fadeOut();
  };

  function ajaxSend(form, data) {
    startSubmitLoader();
    jQuery.ajax({
      type: 'POST',
      crossDomain: true,
      url: 'https://craftera.agency/sendmail.php',
      data
    })
      .done(() => {
        // eslint-disable-next-line no-undef
        fbq('track', 'Lead');
        // window.submitForm();
        // jQuery.ajax({
        //   type: 'POST',
        //   crossDomain: true,
        //   url: 'https://craftera.agency/telegram.php',
        //   data
        // }).done(() => {
        //   console.log('telegram send');
        // }).fail(() => {
        //   console.log('telegram fail');
        // });
        setTimeout(successSubmit,1000);
      })
      .fail(() => {
          setTimeout(failSubmit, 1000);
      })
      .always((r) => {
        setTimeout(() => {
          clearFields(form);
        }, 1000);
        setTimeout(() => {
          clearFields(form);
          clearMSG();
        }, 5000);
      });
  }

  $('.form').on('submit', (e) => e.preventDefault());

  $('.form').each(function (index, el) {
    $(el).validate({
      rules: {
        Name: 'required',
        // Service: 'required',
        Email: {
          required: true,
          email: true,
          regex: /^\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i
        },
        Phone: {
          required: true,
          phoneno: true
        }
      },
      messages: {
        Name: 'enter your name',
        // Service: 'select service you want',
        Phone: 'enter your phone',
        Email: 'enter your email'
      },
      submitHandler() {
        const data = $(el).serialize();
        ajaxSend(el, data);
      }
    });
  });
}
