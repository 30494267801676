import { gsap } from 'gsap';

class MenuActions {
  constructor() {
    this.page = document.querySelector('body');
    this.menu = '.nav';
    this.otherItems = '.nav__header .header__item, .nav__copyright';
    this.navItems = '.nav h2, .nav__menu-list--main li, .nav h3, .nav__menu-list--small li';
    this.navSocial = '.social__wrapper--nav .social'
    this.allItems = `${this.navItems}, ${this.otherItems}`;
    this.menuButton = [...document.querySelectorAll('.menu-btn')];

    this.state = {
      open: false,
    };

    this.params = {
      showWrapper: {
        duration: 0.25,
        y: 0
      },
      showContent: {
        duration: 0.25,
        y: 0,
        opacity: 1,
        stagger: 0.05
      },
      showSocial: {
        x: 0,
        opacity: 1,
        duration: 0.25
      },
      hideWrapper: {
        duration: 0.2,
        y: '-100%'
      },
      hideContent: {
        duration: 0.15,
        y: -20,
        opacity: 0,
        stagger: 0.04
      },
      hideSocial: {
        x: -50,
        opacity: 0,
        duration: .15
      },
      firstPosition: {
        y: -20,
        opacity: 0
      },
      firstPositionSocial: {
        x: -50,
        opacity: 0
      },
      gapBetweenShow: '-=0.12',
      gapBetweenHide: '-=0.12'
    };

    this.tl = gsap.timeline();
  }

  updateMenuState () {
    this.state.open = !this.state.open
  }

  showMenu () {
    this.page.classList.add('overflow');
    this.tl
      .to(this.menu, this.params.showWrapper)
      .to(this.navItems,this.params.showContent, this.params.gapBetweenShow)
      .to(this.navSocial, this.params.showSocial, this.params.gapBetweenShow)
      .to(this.otherItems, this.params.showContent, this.params.gapBetweenShow)
      .call(this.updateMenuState.bind(this))
  }

  hideMenu () {
    this.page.classList.remove('overflow');
    this.tl
      .to(this.navItems, this.params.hideContent)
      .to(this.navSocial, this.params.hideSocial, this.params.gapBetweenHide)
      .to(this.otherItems, this.params.hideContent, this.params.gapBetweenHide)
      .to(this.menu, this.params.hideWrapper, this.params.gapBetweenHide)
      .call(this.updateMenuState.bind(this))
  }

  handlerToggleMenu (button) {
    if (!button) return;
    button.addEventListener('click', () => this.state.open ? this.hideMenu() : this.showMenu());
  }

  bootstrapToggleMenuWatchers () {
    [...document.querySelectorAll('.nav__menu-list--main li, .nav__menu-list--small li'), ...this.menuButton].map(this.handlerToggleMenu.bind(this));
  }

  init () {
    this.bootstrapToggleMenuWatchers();
    this.tl.set(this.allItems, this.params.firstPosition)
           .set(this.navSocial, this.params.firstPositionSocial);
  }

}

export default MenuActions;
