import {gsap} from "gsap";
import firstScreenAnimation from './modules/firstScreenAnimation';
import MenuActions from './modules/menu';
import BrandAnimation from './modules/brandAnimation';
import {buttonScrollTo, observeHeader} from './modules/common';
import onScrollAnimation from './modules/onScrollAnimation';
import portfolioV2 from './modules/portfolioV2';
import services from './modules/services';
import form from './modules/form';
import serviceAnimation from './modules/servicesAnimation';

window.addEventListener("load", () => {
  observeHeader();
  new MenuActions().init();
  services().then(serviceAnimation);
  portfolioV2();
  form();

  onScrollAnimation('.js_intersection_animate');

  buttonScrollTo('.js-button-contact', '.form-order');
  buttonScrollTo('.intro__button', '.services');
  buttonScrollTo('.scroll-down-btn', '.services');

  [...document.querySelectorAll('.nav__menu-list--main li a, .nav__menu-list--small li a')].forEach((link) => {
    const scrollToPath = link.getAttribute('href');
    buttonScrollTo(link, scrollToPath);
  });

  [...document.querySelectorAll('.js_intersection_animate')].forEach((item) => {
    gsap.set(item, {opacity: 0, y: (item.offsetHeight < 300 ? (item.offsetHeight / 3) : (item.offsetHeight / 4))});
  });

  function isMobile() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  };

  if (isMobile()) {
    $('.mobile__detect').addClass('detected__mobile')
  } else {
    $('.mobile__detect').addClass('detected__desktop')
  }


  // start loader and scroll to top
  gsap.to(window, {
    duration: 0.3, scrollTo: 0, onComplete: () => {

      // run first screen base animation
      firstScreenAnimation().then(() => {
        document.body.classList.remove('overflow');

        // run brand element observer animation
        new BrandAnimation().init();

      });
    }
  });


});

