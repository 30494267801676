import { gsap } from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
// import debounce from 'lodash/debounce';

gsap.registerPlugin(ScrollToPlugin);


/*
* @param {string} target - target section
* */
export const scrollTo = (target = '') => {
  gsap.to(window, {duration: 0.7, scrollTo: {y: target, offsetY: 0}});
};

/*
* @param {string} button - buttons handler
* @param {string} target - target section
* */
export const buttonScrollTo = (button = '', target = '') => {
  if(button instanceof Object) {
    button.addEventListener('click', () => scrollTo(target))
  }
  else {
    [...document.querySelectorAll(button)].map((item) => item.addEventListener('click', () => scrollTo(target)))
  }
};


/*
* add/remove decorate class on page scrolling
* */
export const observeHeader = () => {
  const HEADER = document.querySelector('.header');
  const HEADER_WRAPPER = document.querySelector('.header__inner-wrapper');
  const SCROLL_BTN = document.querySelector('.scroll-down-btn');

  const conditionAction = () => {
    if (window.scrollY >= 5) {
      HEADER.classList.add('header--scroll');
      HEADER_WRAPPER.classList.add('header__inner-wrapper--scroll');
      SCROLL_BTN.classList.add('scroll-down-btn--scrolled');
    } else {
      HEADER.classList.remove('header--scroll');
      HEADER_WRAPPER.classList.remove('header__inner-wrapper--scroll');
    }
  };

  // first load
  conditionAction();
  // scroll event
  window.addEventListener('scroll', conditionAction);
};
