import {gsap} from 'gsap';

export default class BrandAnimation {
  constructor() {
    this.container = '.intro__animation-brand';
    this.Oval = document.querySelector('.intro__brand-svg--oval');
    this.C =  document.querySelector('.intro__brand-svg--vector-c');
    this.R =  document.querySelector('.intro__brand-svg--vector-r');
    this.A =  document.querySelector('.intro__brand-svg--vector-a');
    this.F =  document.querySelector('.intro__brand-svg--vector-f');
    this.T =  document.querySelector('.intro__brand-svg--vector-t');
    this.Back =  document.querySelector('.intro__brand-svg--back');
    this.scrollDown =  document.querySelector('.scroll-down-btn');
    this.RAFT = [this.R, this.A, this.F, this.T];
  }

  runBrandAnimation() {
    gsap.defaults({
      ease: "power2.out"
    });
    gsap.fromTo([this.C, this.Oval], {y: '50%', scale: 1}, { opacity: 1, y: 0, z: 0, scale: 1.1, duration: 1.2,
      onComplete: () => {
        gsap.to(this.C, {scale: 1, z: 0, duration: 3});
        gsap.to(this.Oval, {x: '-6.5%', z: 0, scale: 0.8, duration: 1.5});
        gsap.fromTo(this.RAFT, {y: '60%'}, {opacity: 1, y: '0%', z: 0, duration: 2, stagger: 0.12});
        gsap.fromTo(this.Back, {y: 10, scale: 0.9}, {
          opacity: 1, y: 0, z: 0, scale: 1, delay: 1, duration: 1.5, onComplete: () => {
            gsap.fromTo(this.scrollDown, {y: -20}, {opacity: 1, y: 0, z: 0, duration: 0.5});
          }
        });

      }
    })
  }

  init() {
    // data targets
    const target = document.querySelector(this.container);
    const options = {rootMargin: '0px', threshold: 0.4};

    const callBack = (entries, observer) => {
      if (entries[0].isIntersecting) {

        this.runBrandAnimation();

        // destroy listener
        observer.disconnect();
      }
    };

    // create observer listener
    const observer = new IntersectionObserver(callBack, options);

    // run listener
    observer.observe(target);
  }

};
