import Swiper from 'swiper';

export default () => {
  return new Promise((resolve) => {
    const THUMBS = [...document.querySelectorAll('.js_thumbs_inside')];
    const SERVICES = [...document.querySelectorAll('.js_slider_inside')];

    // main navigation
    const swiperServicesNav = new Swiper('.services__nav__wrapper', {
      slidesPerView: 5,
      spaceBetween: 0,
      allowTouchMove: false
    });

    // main services slider
    const swiperServicesMain = new Swiper('.services__slider__services', {
      init: false,
      slidesPerView: 1,
      speed: 1000,
      spaceBetween: 0,
      // effect: 'fade',
      slideClass: 'services__slide__parent',
      slideActiveClass: 'services__slide__parent--active',
      thumbs: {
        slideThumbActiveClass: 'services__nav__item--active',
        swiper: swiperServicesNav
      }
    });

    SERVICES.forEach((item, index) => {
      item.classList.add(`js_slider_inside_${index}`);
      THUMBS[index].classList.add(`js_thumbs_inside_${index}`);

      const THUMBS_LENGTH = [...document.querySelectorAll(`.js_thumbs_inside_${index} .services__container__thumbs-slide`)].length;

      const thumbsSwiper = new Swiper(`.js_thumbs_inside_${index}`, {
        slidesPerView: THUMBS_LENGTH,
        spaceBetween: 0,
        nested: true,
        normalizeSlideIndex: true,
        slideClass: 'services__container__thumbs-slide',
        allowTouchMove: false
      });

      const swiperServices = new Swiper(`.js_slider_inside_${index}`, {
        speed: 1000,
        slidesPerView: 1,
        spaceBetween: 0,
        slideClass: 'services__slide__children',
        slideActiveClass: 'services__slide__children--active',
        // effect: 'fade',
        lazy: true,
        nested: true,
        autoHeight: true,
        normalizeSlideIndex: true,
        preloadImages: false,
        thumbs: {
          swiper: thumbsSwiper,
          slideThumbActiveClass: 'services__container__thumbs-slide--active',
        },
      });
      swiperServicesMain.on('init', () => {
        return resolve(true);
      });
      swiperServicesMain.init();
    });
  });
}
