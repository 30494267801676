import $ from 'jquery';
import Slick from 'slick-carousel';

const portfolioV2 = () => {
  const $SLIDER = $('.portfolio__slider__wrapper');
  const $prevBtn = $('.portfolio__button--prev');
  const $nextBtn = $('.portfolio__button--next');
  const $navigationButtons = $('.portfolio__button');

  const positions = {
    prevBlur: null,
    nextBlur: null,
    current: null,
    last: null,
    activeSlides: null,
  };

  const observeBlurElements = () => {
    positions.activeSlides = $('.slick-active');

    positions.current = $(positions.activeSlides[0]);
    positions.last = $(positions.activeSlides[positions.activeSlides.length - 1]);
    positions.prevBlur = positions.current.prev().get(0);
    positions.nextBlur = positions.last.next().get(0);
  };

  const observeButtons = () => {
    if (!positions.nextBlur) {
      $($nextBtn).fadeOut();
    } else {
      $($nextBtn).fadeIn();
    }

    if (!positions.prevBlur) {
      $($prevBtn).fadeOut();
    } else {
      $($prevBtn).fadeIn();
    }

    $($navigationButtons).attr('disabled', false);
  };

  const nextDirectionUpdates = () => {
    observeBlurElements();

    $(positions.nextBlur).removeClass('blur');
    $(positions.nextBlur).next().addClass('blur');
    $(positions.prevBlur).next().addClass('blur');

    if (!positions.prevBlur) {
      $(positions.current).addClass('blur');
    }

    $SLIDER.slick('slickNext');
  };

  const prevDirectionUpdates = () => {
    observeBlurElements();

    $(positions.prevBlur).removeClass('blur');
    $(positions.prevBlur).prev().addClass('blur');
    $(positions.nextBlur).prev().addClass('blur');

    if (!positions.nextBlur) {
      $(positions.last).addClass('blur');
    }

    $SLIDER.slick('slickPrev');
  };

  const setStartPosition = () => {
    $([positions.nextBlur]).addClass('blur');
    observeButtons();
  };

  $SLIDER.on('init', () => {
    observeBlurElements();
    setStartPosition();
  });

  $SLIDER.slick({
    infinite: false,
    initialSlide: 0,
    slidesToShow: 3,
    speed: 1000,
    draggable: false,
    swipe: false,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1
        }
      },
    ]
  });

  $SLIDER.on('breakpoint', () => {
    observeBlurElements();
    setStartPosition();
    $(positions.activeSlides).removeClass('blur');
  });

  $SLIDER.on('beforeChange', () => {
    $($navigationButtons).attr('disabled', true);
  });

  $SLIDER.on('afterChange', () => {
    observeBlurElements();
    observeButtons();
  });

  $nextBtn.click(nextDirectionUpdates);

  $prevBtn.click(prevDirectionUpdates);
};

export default portfolioV2;
